/** @jsx jsx **/
import { jsx, Text, Container, Heading, ThemeUICSSObject } from 'theme-ui'
import { Button } from '~/components/button'
import HeroImage from '~/components/hero-image'
import { FluidImageObjectType, HeroHeight, ImageTypes } from '~/@types/models'

interface Props extends Partial<ImageTypes> {
  title?: React.ReactNode | string
  description?: string
  buttonLabel?: string
  buttonLink?: string
  buttonVariant?: string
  buttonSx?: ThemeUICSSObject
  titleSx?: ThemeUICSSObject
  titleContainterSx?: ThemeUICSSObject
  descriptionSx?: ThemeUICSSObject
  descriptionContainerSx?: ThemeUICSSObject
  height?: {
    mobile?: keyof typeof HeroHeight
    desktop?: keyof typeof HeroHeight
  }
  heroSx?: ThemeUICSSObject
  imageUrl?: string
}

const HeroImageSection = ({
  title,
  description,
  buttonLabel,
  buttonLink,
  buttonSx,
  buttonVariant,
  image,
  imageMobile,
  titleSx,
  descriptionSx,
  titleContainterSx,
  descriptionContainerSx,
  height,
  heroSx,
  imageV2,
  imageUrl,
}: Props) => {
  const showContent = title || description || buttonLabel || buttonLink
  const images =
    image || imageMobile
      ? {
          default: image as FluidImageObjectType,
          mobile: imageMobile,
        }
      : null

  return (
    <HeroImage
      variant="tertiary"
      as="section"
      images={images}
      imageUrl={imageUrl}
      imageV2={imageV2}
      heightV2={height}
      heroSx={{
        ...heroSx,
      }}
    >
      {showContent && (
        <Container
          sx={{
            color: 'main',
            ...titleContainterSx,
          }}
        >
          <Heading
            as="h2"
            variant="title3"
            sx={{
              mb: 'md2',
              color: 'inherit',
              ...titleSx,
            }}
          >
            {title}
          </Heading>
          <Container
            variant="containerSecondary"
            sx={{
              p: 0,
              maxWidth: 480,
              mb: 'md2',
              ...descriptionContainerSx,
            }}
          >
            <Text
              variant="regular"
              sx={{
                ...descriptionSx,
              }}
            >
              {description}
            </Text>
          </Container>
          {buttonLink && (
            <Button
              variant={buttonVariant}
              label={buttonLabel}
              to={buttonLink}
              as="link"
              sx={{
                ...buttonSx,
              }}
            />
          )}
        </Container>
      )}
    </HeroImage>
  )
}

export default HeroImageSection
