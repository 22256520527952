/** @jsx jsx */
import { Box, Grid, ThemeUIStyleObject, jsx } from 'theme-ui'
import ProductCarousel from '~/components/products-carousel'
import useMediaQuery from '~/hooks/use-media-query'

type Data = Record<string, unknown>
interface Props {
  data: Data[]
  GridItem: () => React.FunctionComponent<Data>
  gridProps?: Record<string, unknown>
  sx?: ThemeUIStyleObject
  carouselOnMobile?: boolean
  gridItemProps?: object
}

const GridTemplate = (props: Props) => {
  const {
    GridItem,
    data,
    gridProps = {},
    carouselOnMobile = false,
    sx = {},
    gridItemProps = {},
  } = props

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[0]})`,
  })

  const showCarousel = isMobile && carouselOnMobile

  return (
    <div sx={sx}>
      {showCarousel ? (
        <ProductCarousel
          data={data.map(item => {
            return () => <GridItem {...item} {...gridItemProps} />
          })}
        />
      ) : (
        <Grid columns={[1, 2, 4]} gap={16} {...gridProps}>
          {data.map((item, index) => (
            <Box key={index}>
              <GridItem {...item} {...gridItemProps} />
            </Box>
          ))}
        </Grid>
      )}
    </div>
  )
}

export default GridTemplate
