/** @jsx jsx */
import { jsx, Container } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import Section from '~/components/section'
import { space } from '~/gatsby-plugin-theme-ui'
import GridTemplate from '~/components/grid-template'
import { ArticlePageInterface } from '~/@types/models'
import BlogPost from '~/components/blog-post'

interface Props {
  currentArticleHandle: string
}

interface QueryResult {
  site: {
    siteMetadata: {
      siteUrl: string
    }
  }
  recentArticles: {
    edges: Array<{
      node: ArticlePageInterface
    }>
  }
}

const RecentArticles = ({ currentArticleHandle }: Props) => {
  const { recentArticles, site } = useStaticQuery<QueryResult>(graphql`
    query RecentArticles {
      site {
        siteMetadata {
          siteUrl
        }
      }
      recentArticles: allShopifyArticle(sort: { order: DESC, fields: publishedAt }, limit: 4) {
        edges {
          node {
            handle
            image {
              url
            }
            blog {
              handle
            }
            title
          }
        }
      }
    }
  `)

  const articles = recentArticles.edges.filter(
    article => article.node.handle !== currentArticleHandle && article.node
  )
  const siteUrl = site.siteMetadata.siteUrl

  return (
    <Container variant="container">
      <Section label="Recent posts">
        <Container
          variant="container"
          sx={{
            maxWidth: 'container',
            pb: [space.l2, null, space.xl2 * 1.5],
          }}
        >
          <GridTemplate
            data={articles.map(article => ({
              article: article.node,
            }))}
            GridItem={({ article }: { article: ArticlePageInterface }) => {
              const slug = `${siteUrl}/blogs/${article.blog.handle}/${article.handle}`
              return (
                <BlogPost
                  contentWidth="wide"
                  key={article.handle}
                  imageV2={article.image.url}
                  title={article.title}
                  excerpt={article.excerpt}
                  readMore={slug}
                  contentSx={{
                    pl: [28, null, 0],
                    pr: [0, null, 95],
                    maxWidth: '100%',
                  }}
                />
              )
            }}
            gridProps={{
              columns: [1, 2, 3],
              gap: [24],
            }}
          />
        </Container>
      </Section>
    </Container>
  )
}

export default RecentArticles
